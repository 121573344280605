import React from "react";
import style from "./Section.module.css";

const Section = (props) => {
  return (
    <React.Fragment>
    {window.location.pathname !== "/admin/eventsdelete" ? ( props.show &&
      <div className={style.sectiondiv}>
        <div className={style.container}>
          <div className={style.cutdiv}>
            <img
              src={props.img}
              alt={props.name}
              width="45px"
              className={style.categoryimg}
            />
            <h2
              className={`${props.class} ${style.name}`}
              style={{ textTransform: "capitalize" }}
            >
              {props.name}{" "}
            </h2>
            <div className={style.sectionlines}>
              <div
                className={style.topline}
                style={{
                  backgroundColor: `${props.color}`,
                  border: `2px solid ${props.color}`,
                }}
              ></div>
              <div className={style.bottomline}></div>
            </div>
          </div>
        </div>
        <div className={style.clild}>{props.children}</div>
      </div>)
      :( <div className={style.sectiondiv}>
        <div className={style.container}>
          <div className={style.cutdiv}>
            <img
              src={props.img}
              alt={props.name}
              width="45px"
              className={style.categoryimg}
            />
            <h2
              className={`${props.class} ${style.name}`}
              style={{ textTransform: "capitalize" }}
            >
              {props.name}{" "}
            </h2>
            <div className={style.sectionlines}>
              <div
                className={style.topline}
                style={{
                  backgroundColor: `${props.color}`,
                  border: `2px solid ${props.color}`,
                }}
              ></div>
              <div className={style.bottomline}></div>
            </div>
          </div>
        </div>

        <div className={style.clild}>{props.children}</div>
      </div>)

    }
    </React.Fragment>
  );
};

export default Section;
