/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { Button } from "reactstrap";
import classes from "./Events.module.css";
import Event from "../../components/Event/Event";
import Section from "../../components/Section/Section";
import cross from "../../assets/cross.svg";
import { Modal } from "react-bootstrap";
import ViewEvents from "./ViewEvents";
import APIService from "../../services/axios";

class Events extends Component {
  constructor() {
    super();
    this.state = {
      events: [],
      Categories: [],
      modal: false,
    };
  }

  componentWillMount() {
    let token = null;

    if (window.location.pathname === "/admin/panel") {
      token = localStorage.getItem("authToken");
    }

    APIService
      .get("/category", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        // console.log(res);
        this.setState({ Categories: res.data.reverse() });
      })
      .catch((err) => {});

    APIService
      .get("/events", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        res.data.data = res.data.data.reverse();
        this.setState({ events: res.data.data });
        // console.log("events",res.data.data)
      })
      .catch((err) => {});
  }
  render() {
    return (
      <React.Fragment>
        {/* <center>
          <div
            onClick={() => this.setState({ modal: true })}
            className={classes.reg_link}
          >
            View Ongoing Events
          </div>
        </center> */}

        <Modal
          show={this.state.modal}
          class="modal-dialog modal-lg "
          dialogClassName="custom-dialog "
          contentClassName={classes.my_content + " custom-dialog"}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
          onHide={() => this.setState({ modal: true })}
        >
          <Modal.Body class="modal-body">
            <div className={classes.head}>
              <h1>Aaruush'21 Events</h1>
              <img
                onClick={() => this.setState({ modal: false })}
                className={classes.cross + " float-right-top"}
                src={cross}
                alt="cross"
              />
            </div>

            <ViewEvents />
          </Modal.Body>
        </Modal>

        <div className={classes.event_container}>
          <div className={classes.scroll}>
            {this.state.Categories.filter((data) => {
              if (data.name === "common registration") {
                return data;
              }
            }).map((category) => (
              <Section
                img={category.imageUrl}
                name={category.name}
                color={category.colorCode}
                show={category.show}
              >
                {this.state.events
                  .filter((data) => {
                    if (data.category === category.name) {
                      return data;
                    }
                  })
                  .map((data) => (
                    <React.Fragment>
                      {/* {console.log("pop event",data.sponsors)} */}
                      <Event
                        key={data._id}
                        name={data.heading}
                        description={data.description}
                        image={data.imageUrl}
                        reglink={data.link}
                        caption={data.caption}
                        bgColor={data.bgColor}
                        date={data.date}
                        time={data.time}
                        platform={data.platform}
                        speaker={data.speaker}
                        sponsors={data.sponsors}
                        secondlink={data.secondlink}
                        cr={data.cr}
                      />

                      {window.location.pathname === "/admin/eventsdelete" ? (
                        <Button
                          className="col-12 mb-4"
                          color="danger"
                          outline
                          onClick={() => this.props.EventRemove(data._id)}
                        >
                          Remove
                        </Button>
                      ) : null}
                    </React.Fragment>
                  ))}
                {window.location.pathname === "/admin/eventsdelete" ? (
                  category.show ? (
                    <Button
                      className="col-12 mb-4"
                      color="danger"
                      outline
                      onClick={() => this.props.CategoryHide(category._id)}
                    >
                      Hide Cateogry
                    </Button>
                  ) : (
                    <Button
                      className="col-12 mb-4"
                      color="danger"
                      outline
                      onClick={() => this.props.CategoryShow(category._id)}
                    >
                      Show Cateogry
                    </Button>
                  )
                ) : null}
              </Section>
            ))}
            {this.state.Categories.filter((data) => {
              if (data.name !== "common registration") {
                return data;
              }
            }).map((category) => (
              <Section
                img={category.imageUrl}
                name={category.name}
                color={category.colorCode}
                show={category.show}
              >
                {this.state.events
                  .filter((data) => {
                    if (data.category === category.name) {
                      return data;
                    }
                  })
                  .map((data) => (
                    <React.Fragment>
                      {/* {console.log("pop event",data.sponsors)} */}
                      <Event
                        key={data._id}
                        name={data.heading}
                        description={data.description}
                        image={data.imageUrl}
                        reglink={data.link}
                        caption={data.caption}
                        bgColor={data.bgColor}
                        date={data.date}
                        time={data.time}
                        platform={data.platform}
                        speaker={data.speaker}
                        sponsors={data.sponsors}
                        secondlink={data.secondlink}
                        cr={data.cr}
                      />

                      {window.location.pathname === "/admin/eventsdelete" ? (
                        <Button
                          className="col-12 mb-4"
                          color="danger"
                          outline
                          onClick={() => this.props.EventRemove(data._id)}
                        >
                          Remove
                        </Button>
                      ) : null}
                    </React.Fragment>
                  ))}
                {window.location.pathname === "/admin/eventsdelete" ? (
                  category.show ? (
                    <Button
                      className="col-12 mb-4"
                      color="danger"
                      outline
                      onClick={() => this.props.CategoryHide(category._id)}
                    >
                      Hide Cateogry
                    </Button>
                  ) : (
                    <Button
                      className="col-12 mb-4"
                      color="danger"
                      outline
                      onClick={() => this.props.CategoryShow(category._id)}
                    >
                      Show Cateogry
                    </Button>
                  )
                ) : null}
              </Section>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Events;
