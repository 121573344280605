import React from "react";
import style from "./Social.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import insta from "../../assets/socialmedia_mobile/insta.svg";
import facebook from "../../assets/socialmedia_mobile/facebook.svg";
import twitter from "../../assets/socialmedia_mobile/twitter.svg";
import linkedin from "../../assets/socialmedia_mobile/linkedin.svg";
import discord from "../../assets/socialmedia_mobile/discord.svg";
import youtube from "../../assets/socialmedia_mobile/youtube.svg";

const Social = (props) => {
  // function randomcolor() {
  //   const colors = ["#DE5F04", "#D30637", "#01A369", "#0D91CA"];
  //   const random = colors[Math.floor(Math.random() * colors.length)];
  //   return random;
  // }

  // const random = randomcolor();

  return (
    <React.Fragment>
      {/* <div
        className={style.social_container}
        style={{ backgroundColor: `${random}` }}
      > */}
      {/* <div
          className={style.social_div}
          style={{ backgroundColor: `${random}` }}
        ></div> */}
      <div className={style.social_icons}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/aaruush_srm/"
        >
          <img src={insta} alt={"insta"} className={style.fb} />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/aaruush.srm/"
        >
          <img src={facebook} alt={"insta"} />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/aaruushsrmist?lang=en"
        >
          <img src={twitter} alt={"insta"} />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/aaruush"
        >
          <img src={linkedin} alt={"insta"} />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://discord.gg/tX9XHq2ENh"
        >
          <img src={discord} alt={"insta"} />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.youtube.com/channel/UC6mwWpwkZchii-oyWz0v3dw"
        >
          <img src={youtube} alt={"insta"} />
        </a>

        <div className={style.bottomline}></div>
      </div>
      {/* </div> */}
    </React.Fragment>
  );
};

export default Social;
