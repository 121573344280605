import React, { useState, useEffect } from "react";
import classes from "./Events.module.css";
import Single from "./Single";
import APIService from "../../services/axios";

const ViewEvents = () => {
  const [date, setdate] = useState("16");
  const [data, setdata] = useState(null);

  useEffect(() => {
    APIService
      .get("/a21events")
      .then((res) => {
        setdata(res.data);
        console.log("events", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <React.Fragment>
      <div className={classes.datehead}>
        <div
          onClick={() => setdate("16")}
          className={`${classes.singledate} ${
            date === "16" ? classes.active : ""
          }`}
        >
          16th Oct
        </div>
        <div
          onClick={() => setdate("17")}
          className={`${classes.singledate} ${
            date === "17" ? classes.active : ""
          }`}
        >
          17th Oct
        </div>
        <div
          onClick={() => setdate("18")}
          className={`${classes.singledate} ${
            date === "18" ? classes.active : ""
          }`}
        >
          18th Oct
        </div>
        <div
          onClick={() => setdate("19")}
          className={`${classes.singledate} ${
            date === "19" ? classes.active : ""
          }`}
        >
          19th Oct
        </div>
      </div>
      <div>
        {data &&
          data
            // eslint-disable-next-line
            .filter((event) => {
              if (event.date === date) {
                return data;
              }
            })
            .map((data) => <Single data={data} />)}
      </div>
    </React.Fragment>
  );
};

export default ViewEvents;
