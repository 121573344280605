import React from "react";
import classes from "./Events.module.css";
// ";
import zoom from "../../assets/zoom.svg";
import gmeet from "../../assets/google-meet.svg";
// import cr from "../../assets/cr.svg";
import domain from "../../assets/domain.svg";
import link from "../../assets/link.svg";
import time from "../../assets/time.svg";

const Single = ({ data }) => {
  // console.log(data);
  return (
    <React.Fragment>
      <div className={classes.eventcontainer}>
        <div>
          <h3 className={classes.eventname}>{data.name}</h3>
        </div>
        <div className={classes.eventdetails}>
          <div className={classes.domain}>
            <img src={domain} className={classes.image} alt="domain" />
            {data.domain}
          </div>
          <div className={classes.domain}>
            <img src={time} className={classes.image} alt="time" />
            {data.time}
          </div>
          <div className={classes.eventlink}>
            {data.link !== "abc" ? (
              <>
                <img src={link} className={classes.image} alt="link" />
                <a target="_blank" rel="noopener noreferrer"  href={data.link}>
                  Event Link
                </a>
              </>
            ) : (
              "Starting Soon"
            )}
          </div>
          <div className={`${classes.domain} ${classes.platform}`}>
            {data.platform === "gmeet" && <img src={gmeet} alt="platform" />}
            {data.platform === "zoom" && <img src={zoom} alt="platform" />}
          </div>
        </div>
      </div>
      <hr></hr>
    </React.Fragment>
  );
};

export default Single;
