import React, { useState } from "react";

import style from "./Event.module.css";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import datesvg from "../../assets/date.svg";
import timesvg from "../../assets/time.svg";
// import zoom from "../../assets/zoom.svg";
import arrowrightsvg from "../../assets/arrowright.svg";
import arrowblacksvg from "../../assets/arrowblack.svg";
import cross from "../../assets/cross.svg";
import more from "../../assets/Moreaboutevent.svg";
import speakername from "../../assets/Speaker_name.svg";
import platform from "../../assets/online-meeting.svg";

// social images
// import insta from "../../assets/socialmedia_mobile/insta.svg";
// import facebook from "../../assets/socialmedia_mobile/facebook.svg";
// import twitter from "../../assets/socialmedia_mobile/twitter.svg";
// import linkedin from "../../assets/socialmedia_mobile/linkedin.svg";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Event = (props) => {
  const [openModal, setOpenModal] = useState(false);
  // https://s3.ap-south-1.amazonaws.com/links.aaruush/competition/4dad0df0-5cbb-4d18-b472-babe8d6fdab3-hacksummit.jpeg
  const data = {
    name: `${props.name}`,
    description: `${props.description}`,
    caption: `${props.caption}`,
    image: props.image,
    reglink: `${props.reglink}`,
    date: `${props.date}`,
    time: `${props.time}`,
    platform: `${props.platform}`,
    sponsors: props.sponsors,
    // sponsors: props.sponsors.length ? props.sponsors[0].split(",") : [],
    speaker: `${props.speaker}`,
    backgroundcolor: `${props.bgColor}`,
    secondlink: `${props.secondlink}`,
    cr: `${props.cr}`,
  };
  // [
  //       "https://skillvertex.in/wp-content/uploads/2021/06/1614405687574.png",
  //       "https://skillvertex.in/wp-content/uploads/2021/06/1614405687574.png",
  //       "https://skillvertex.in/wp-content/uploads/2021/06/1614405687574.png",
  //     ]
  return (
    <>
      <div
        className={style.card + " card mb-4 pointer"}
        style={{
          borderRadius: "1rem",
          backgroundColor: `${data.backgroundcolor}`,
        }}
        onClick={() => {
          setOpenModal(true);
        }}
      >
        {data.cr === "true" && <div className={style.CR}>CR Required</div>}
        <div className={style.card_body + " card-body"}>
          <h2
            className={style.name + " card-title font-weight-bold"}
            style={{ textTransform: "capitalize" }}
          >
            {props.name}
          </h2>
          <div className={style.desc}>
            <h5 className="desc">{props.description}</h5>
            <img
              style={{ cursor: "pointer" }}
              src={arrowblacksvg}
              alt="arrow"
              className={style.svg_fill}
            ></img>
          </div>
        </div>
      </div>

      <Modal
        show={openModal}
        class="modal-dialog modal-lg "
        dialogClassName="custom-dialog "
        contentClassName={style.my_content + " custom-dialog"}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        onHide={() => setOpenModal(false)}
      >
        <div className={"modal-header"}>
          <div className={style.registernow}>
            <h5
              className={style.modal_title + " modal-title"}
              id="staticBackdropLabel"
            >
              {data.name}
            </h5>

            {/* <a href={data.reglink} className={style.head_reg_link + " float-right"}>
                      <div className={style.head_reglink}>
                        Register Now
              </div>
          </a> */}
            <a
              href={data.reglink}
              className={style.head_reglink + " btn btn-outline-primary"}
            >
              Register Now
            </a>
            {/* <a href={data.reglink} className={style.head_reg_link}>
              <div className={style.reglink}>Register Now</div>
            </a> */}
          </div>
          {/* <h4 className={"pl-2"}>{data.description}</h4> */}

          <img
            onClick={() => setOpenModal(false)}
            className={style.cross + " float-right"}
            src={cross}
            alt="cross"
          />
        </div>
        <Modal.Body class="modal-body">
          <div className={style.modalcontainer}>
            <div className={style.eventinfo}>
              <div>
                {/* <h1 className={style.eventname}>{data.name}</h1> */}
                <h1 className={style.eventname}>{data.description}</h1>
                <div>
                  {/* <h4 className={style.description}>{data.description}</h4> */}
                </div>
              </div>
              <p className={style.caption + " modal-dialog-scrollable"}>
                {data.caption}
              </p>
            </div>

            {data.sponsors.length ? (
              <>
                <div className={style.eventsponsors}>
                  <div>
                    <h3 className={style.sponsorName}>Our Sponsors</h3>
                  </div>
                  <div className={style.sponsorimages}>
                    {data.sponsors.map((sponsor) => (
                      <img
                        src={sponsor}
                        alt="sponsorlogo"
                        className={"align-middle"}
                      ></img>
                    ))}
                  </div>
                </div>
              </>
            ) : null}

            {/* <div className={style.eventposter}> */}
            <Carousel
              autoPlay
              showArrows={true}
              className={style.eventposter}
              infiniteLoop
              dynamicHeight
              showThumbs={false}
              interval={3000}
              emulateTouch
              swipeable
              showIndicators
              showStatus={false}
            >
              {data.image.map((imageUrl, key) => (
                <div key={key} className={style.eventcarousel}>
                  <img src={imageUrl} alt="poster" />
                </div>
              ))}
            </Carousel>
            {/* <img src={data.image} alt="eventposter" /> */}
            {/* </div> */}

            <div className={style.eventdetailscontainer}>
              <div className={style.eventdetails}>
                {data.date !== "NA" && (
                  <div className={style.detail}>
                    <img src={datesvg} alt="date"></img>
                    <h6>{data.date}</h6>
                  </div>
                )}
                {data.time !== "NA" && (
                  <div className={style.detail}>
                    <img src={timesvg} alt="time"></img>
                    <h6>{data.time}</h6>
                  </div>
                )}
                {data.platform !== "NA" && (
                  <div className={style.detail}>
                    <img src={platform} alt="platform"></img>
                    <h6 style={{ textTransform: "capatilize" }}>
                      {data.platform}
                    </h6>
                  </div>
                )}
                {data.speaker !== "NA" && (
                  <div className={style.detail}>
                    <img src={speakername} alt="speaker"></img>
                    <h6>{data.speaker}</h6>
                  </div>
                )}

                {data.secondlink !== "NA" && (
                  <div className={style.detail}>
                    <img src={more} alt="secondlink"></img>
                    <a
                      href={data.secondlink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h6>Read More About This</h6>
                    </a>
                  </div>
                )}
                {/* <div className={style.socials}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={data.instagram}
                  >
                    <img src={insta} alt={"insta"} className={style.fb} />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={data.twitter}
                  >
                    <img src={facebook} alt={"insta"} />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={data.linkedin}
                  >
                    <img src={linkedin} alt={"insta"} />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={data.twitter}
                  >
                    <img src={twitter} alt={"insta"} />
                  </a>
                </div> */}

                <a href={data.reglink} className={style.reg_link}>
                  <div className={style.reglink}>
                    Register Now
                    <img src={arrowrightsvg} alt="arrow"></img>
                  </div>
                </a>
              </div>
            </div>

            {/* 
            <div className={style.eventposter}>
              <img src={data.image} alt="eventposter" />
            </div>

            

            
            
            <div className={style.eventdetailscontainer}>
              <div className={style.eventdetails}>
                {data.date !== "NA" && (
                  <div className={style.detail}>
                    <img src={datesvg} alt="date"></img>
                    <h6>{data.date}</h6>
                  </div>
                )}
                {data.time !== "NA" && (
                  <div className={style.detail}>
                    <img src={timesvg} alt="time"></img>
                    <h6>{data.time}</h6>
                  </div>
                )}
                {data.platform !== "NA" && (
                  <div className={style.detail}>
                    <img src={timesvg} alt="platform"></img>
                    <h6 style={{ textTransform: "capatilize" }}>
                      {data.platform}
                    </h6>
                  </div>
                )}
                {data.speaker !== "NA" && (
                  <div className={style.detail}>
                    <img src={timesvg} alt="speaker"></img>
                    <h6>{data.speaker}</h6>
                  </div>
                )}

                {data.secondlink !== "NA" && (
                  <div className={style.detail}>
                    <img src={timesvg} alt="secondlink"></img>
                    <a
                      href={data.secondlink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h6>Read More About This</h6>
                    </a>
                  </div>
                )}

                <a href={data.reglink} className={style.reg_link}>
                  <div className={style.reglink}>
                    Register Now
                    <img src={arrowrightsvg} alt="arrow"></img>
                  </div>
                </a>
              </div>
            </div>
 */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Event;
