import React, { useState, useEffect } from "react";
// ";
import Event from "../Event/Event";
import APIService from "../../services/axios";

import "bootstrap/dist/css/bootstrap.min.css";
// import style from "../Section/Section.module.css";

function DomainEvents() {
  const [events, setEvents] = useState([]);
  // const [category, setCategory] = useState()

  useEffect(() => {
    let token = null;

    if (window.location.pathname === "/admin/panel") {
      token = localStorage.getItem("authToken");
    }

    APIService
      .get("/events", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        res.data.data = res.data.data.reverse();
        // eslint-disable-next-line
        res.data.data = res.data.data.filter((data) => {
          if (data.category === "domainevents") {
            return data;
          }
        });
        setEvents(res.data.data);
        // console.log("events", res.data.data)
      })
      .catch((err) => {});
  }, []);

  return (
    <>
      <h2
        className="text-capitalize text-center fw-bolder text-xl font-bold"
        style={{
          color: "#3a5385",
          fontWeight: 600,
          fontSize: "46px",
          backgroundColor: "#F2F2F2",
        }}
      >
        Domain Events
      </h2>

      <div className="container col-sm-8 col-lg-10 mx-auto my-5">
        <div className="row mx-auto">
          {console.log(events)}
          {events.map((data, index) => (
            <div key={index} className="col-lg-4">
              <Event
                key={data._id}
                name={data.heading}
                description={data.description}
                image={data.imageUrl}
                reglink={data.link}
                caption={data.caption}
                bgColor={data.bgColor}
                date={data.date}
                time={data.time}
                platform={data.platform}
                speaker={data.speaker}
                sponsors={data.sponsors}
                secondlink={data.secondlink}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
export default DomainEvents;
