import React from "react";

import classes from "./Header.module.css";
import aaruushLogo from "../../assets/a22/a'22Header1.png";
// import aaruushLogo from "../../assets/aaruush'21headerblack.png";
import aaruushgif from "../../assets/spinner_white.gif";
// import { Link } from "react-router-dom";

const Header = (props) => {
  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.logo}>
          <a href="https://aaruush.org/">
            <img src={aaruushgif} alt="aaruushgif" className={classes.gif} />
          </a>
          <img
            src={aaruushLogo}
            alt="aaruushLogo"
            onClick={props.aaruushLogoClicked}
            className={classes.aaruushlogo}
          />
        </div>
      </div>
     
    </React.Fragment>
  );
};

export default Header;
